/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import PageHeader from "components/PageHeader/PageHeader.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import Services from "views/Services.js";
import CustomNavBar from "components/Navbars/Navbar";
import TeckStack from "views/TeckStack";
import Portfolio from "views/Portfolio";
import Products from "views/Products";
import AboutUs from "views/AboutUs";

export default function LandingPage() {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);
  return (
    <>
      <CustomNavBar />
      <div className="wrapper">
        <PageHeader />
        <div className="main">
          <Services />
          <TeckStack />
          <Portfolio />
          <Products />
          <AboutUs />
        </div>
        <Footer />
      </div>
    </>
  );
}
