/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

export default function AboutUs() {
  const [iconTabs, setIconsTabs] = React.useState(1);
  const [textTabs, setTextTabs] = React.useState(4);
  return (
    <div id="about-us" className="section section-tabs">
      <Container>
        <div className="title">
          <h3 className="mb-3">Sobre nós</h3>
        </div>
        <Row>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 1,
                      })}
                      onClick={(e) => setIconsTabs(1)}
                      href="#"
                    >
                      <i className="tim-icons icon-single-copy-04" />
                      Sumário
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 2,
                      })}
                      onClick={(e) => setIconsTabs(2)}
                      href="#"
                    >
                      <i className="tim-icons icon-spaceship " />
                      Nossa Missão
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + iconTabs}>
                  <TabPane tabId="link1">
                    <p>
                      A CHUB <sup>ao</sup> é uma empresa privada que opera no
                      cenário dinâmico do setor de TI. Somos especializados em
                      fornecimento de serviços e consultoria de TI com um
                      compromisso em providenciar valor e experiência
                      excepcionais.
                      <br />
                      <br />A CHUB <sup>ao</sup> apresenta-se como um aliado
                      confiável das organizações ou indivíduos que desejam
                      impulsionar sua tecnologia capacidades e agilizar as
                      operações, bem como para engenheiros de software que
                      buscam um parceiro confiável e focado em suas metas
                      profissionais e financeiras, ao mesmo tempo que nutre seu
                      crescimento na área de especialização escolhida.
                      <br />
                      <br />
                      Essa dedicação à excelência garante que a empresa
                      permanece na vanguarda do desenvolvimento da indústria,
                      atendendo às diversas necessidades de nossa clientela. Em
                      um ambiente digital em rápida evolução, os serviços
                      oferecidos pela CHUB <sup>ao</sup> são indispensáveis
                      ​​para quem pretende navegar as complexidades da
                      tecnologia de informação de forma eficaz.
                    </p>
                  </TabPane>
                  <TabPane tabId="link2">
                    <p>
                      A CHUB <sup>ao</sup> foi criada com o objectivo de dar
                      resposta a uma problema significativo e generalizado tanto
                      na indústria de TI e a esfera corporativa. O problema em
                      questão diz respeito ao ausência de harmonia entre
                      habilidosos e entusiasmados engenheiros, que
                      frequentemente enfrentam subvalorização e demandas
                      excessivas, sem receber o atendimento adequado, apoio,
                      reconhecimento ou remuneração justa e clientes que possuem
                      expectativas impraticáveis ​​juntamente com uma
                      compreensão limitada ou consideração pela experiência dos
                      engenheiros.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 4,
                      })}
                      onClick={(e) => setTextTabs(4)}
                      href="#"
                    >
                      <i className="tim-icons icon-single-02" />O team
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 5,
                      })}
                      onClick={(e) => setTextTabs(5)}
                      href="#"
                    >
                      <i className="tim-icons icon-square-pin" />
                      Localização
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + textTabs}>
                  <TabPane tabId="link4">
                    <Row className="mt-5 mt-sm-5 text-center">
                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          Wilson Cardoso
                        </small>
                      </Col>
                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          Lauren Cardoso
                        </small>
                      </Col>
                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          Veríssimo Lupale
                        </small>
                      </Col>

                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          Marco Cirilo
                        </small>
                      </Col>
                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          José Isaías
                        </small>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="link5">
                    <p>Our team work remotely from the following locations</p>
                    <Row className="mt-5 mt-sm-5 text-center">
                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-location-100.png")}
                          style={{ width: "50px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-2">
                          South Africa
                        </small>
                      </Col>
                      <Col sm="4" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-location-100.png")}
                          style={{ width: "50px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-2">
                          Angola
                        </small>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
