/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";

export default function Products() {
  return (
    <div id="products" className="section section-signup">
      <Container>
        <div className="title">
          <h3 className="mb-3">Produtos</h3>
        </div>
        <div className="squares square-1" />
        <div className="squares square-2" />
        <div className="squares square-3" />
        <div className="squares square-4" />

        {/* <Row>
          <Col md="4">
            <hr className="line-info" />
            <h1>
              Choose the coin{" "}
              <span className="text-info">that fits your needs</span>
            </h1>
          </Col>
        </Row> */}
        <Row className="mt-5 mt-sm-5">
          <Col md="4">
            <Card className="card-coin card-plain">
              <CardHeader>
                <img
                  alt="..."
                  className="img-center img-fluid"
                  src={require("assets/img/kwendapay_logo.png")}
                  style={{ margin: 50 }}
                />
                <Badge
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  color="success"
                >
                  Desenvolvimento
                </Badge>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="text-center" md="12">
                    <h4 className="text-uppercase">KwendaPay</h4>
                    {/* <span>Plan</span> */}
                    {/* <hr className="line-primary" /> */}
                  </Col>
                </Row>
                {/* <Row>
                  <ListGroup>
                    <ListGroupItem>50 messages</ListGroupItem>
                    <ListGroupItem>100 emails</ListGroupItem>
                    <ListGroupItem>24/7 Support</ListGroupItem>
                  </ListGroup>
                </Row> */}
              </CardBody>
              <CardFooter className="text-center">
                <Button disabled className="btn-simple" color="success">
                  Subscrição
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-coin card-plain">
              <CardHeader>
                <img
                  alt="..."
                  className="img-center img-fluid"
                  src={require("assets/img/salo_logo.png")}
                  style={{ margin: 50 }}
                />
                <Badge
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  color="warning"
                >
                  Planejamento
                </Badge>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="text-center" md="12">
                    <h4 className="text-uppercase">Salo</h4>
                    {/* <span>Plan</span> */}
                    {/* <hr className="line-success" /> */}
                  </Col>
                </Row>
                {/* <Row>
                  <ListGroup>
                    <ListGroupItem>150 messages</ListGroupItem>
                    <ListGroupItem>1000 emails</ListGroupItem>
                    <ListGroupItem>24/7 Support</ListGroupItem>
                  </ListGroup>
                </Row> */}
              </CardBody>
              <CardFooter className="text-center">
                <Button disabled className="btn-simple" color="success">
                  Subscrição
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-coin card-plain">
              <CardHeader>
                <img
                  alt="..."
                  className="img-center img-fluid"
                  src={require("assets/img/mandavir_logo.png")}
                  style={{ margin: 50 }}
                />
                <Badge
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  color="warning"
                >
                  Planejamento
                </Badge>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="text-center" md="12">
                    <h4 className="text-uppercase">Mandavir</h4>
                    {/* <span>Plan</span> */}
                    {/* <hr className="line-info" /> */}
                  </Col>
                </Row>
                {/* <Row>
                  <ListGroup>
                    <ListGroupItem>350 messages</ListGroupItem>
                    <ListGroupItem>10K emails</ListGroupItem>
                    <ListGroupItem>24/7 Support</ListGroupItem>
                  </ListGroup>
                </Row> */}
              </CardBody>
              <CardFooter className="text-center">
                <Button disabled className="btn-simple" color="info">
                  Subscrição
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
