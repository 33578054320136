import React from "react";
import classnames from "classnames";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

export default function ContactForm() {
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  return (
    <Card className="card-register">
      <CardBody>
        <Form className="form">
          <InputGroup
            className={classnames({
              "input-group-focus": fullNameFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Nome completo"
              type="text"
              onFocus={(e) => setFullNameFocus(true)}
              onBlur={(e) => setFullNameFocus(false)}
            />
          </InputGroup>
          <InputGroup
            className={classnames({
              "input-group-focus": emailFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-email-85" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="text"
              onFocus={(e) => setEmailFocus(true)}
              onBlur={(e) => setEmailFocus(false)}
            />
          </InputGroup>

          <InputGroup
            className={classnames({
              "input-group-focus": emailFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              {/* <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText> */}
            </InputGroupAddon>
            <Input
              placeholder="Mensagem..."
              type="textarea"
              rows="5"
              // onFocus={(e) => setEmailFocus(true)}
              // onBlur={(e) => setEmailFocus(false)}
            />
          </InputGroup>
        </Form>
      </CardBody>
      <CardFooter>
        {/* <Button className="btn-square" color="primary" size="lg">
              Send email
            </Button> */}

        <Button
          className="btn-simple btn-square"
          color="primary"
          disabled
          // to="landing-page"
          // tag={Link}
          // onClick={() => setFormModal(true)}
        >
          Enviar e-mail
        </Button>
      </CardFooter>
    </Card>
  );
}
