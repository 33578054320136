/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  UncontrolledCarousel,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

const carouselItems = [
  {
    src: require("assets/img/BMW-Portfolio.png"),
    altText: "Slide 1",
    caption: "",
  },
  {
    src: require("assets/img/Allegra-Portfolio.png"),
    altText: "Slide 2",
    caption: "",
  },
  {
    src: require("assets/img/angolan_embassy.png"),
    altText: "Slide 3",
    caption: "",
  },
];
export default function Portfolio() {
  return (
    <div
      id="portfolio"
      className="section section-lg section-safe"
      // data-background-color="white"
    >
      <img alt="..." className="path" src={require("assets/img/path1.png")} />
      {/* <div className="space-50" /> */}

      <Container>
        <div className="title">
          <h3 className="mb-5">Portfólio</h3>
        </div>
        <Row className="row-grid justify-content-between">
          <Col md="5">
            <img
              alt="..."
              className="img-fluid floating"
              src={require("assets/img/portfolio.png")}
            />
            <Card className="card-stats bg-danger">
              <CardBody>
                <div className="justify-content-center">
                  <div className="numbers">
                    <CardTitle tag="p">5 </CardTitle>
                    <p className="card-category text-white">Projectos</p>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="card-stats bg-info">
              <CardBody>
                <div className="justify-content-center">
                  <div className="numbers">
                    <CardTitle tag="p" className="text-dark">
                      4
                    </CardTitle>
                    <p className="card-category">Clientes satisfeitos</p>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="card-stats bg-default">
              <CardBody>
                <div className="justify-content-center">
                  <div className="numbers">
                    <CardTitle tag="p" className="text-dark">
                      4
                    </CardTitle>
                    <p className="card-category text-black">Negócios</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <UncontrolledCarousel
              items={carouselItems}
              indicators={true}
              autoPlay={true}
              className="mt-4 mt-sm-4"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
