/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ContactForm from "components/ContactForm/ContactForm";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";

export default function PageHeader() {
  const [formModal, setFormModal] = React.useState(false);

  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center brand" data-background-color="black">
          <h1 className="h1-seo">Perturbe a indústria</h1>
          <h5 className="d-none d-sm-block">
            Por aqui, porém, não olhamos para trás por muito tempo. Seguimos em
            frente, abrindo novas portas e fazendo coisas novas… e a curiosidade
            continua a levar-nos por novos caminhos - Walt Disney
          </h5>

          <Row>
            <Col md="10">
              <Button
                color="default"
                size="lg"
                onClick={() => setFormModal(true)}
              >
                Contacte-nos
              </Button>
            </Col>
          </Row>
        </div>

        <CustomModal
          title="Contacte-nos"
          formModal={formModal}
          setFormModal={setFormModal}
        >
          <ContactForm />
        </CustomModal>
      </Container>
    </div>
  );
}
