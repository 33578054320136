/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

export default function Footer() {
  const [formModal, setFormModal] = React.useState(false);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="2"></Col>
          <Col md="2"></Col>

          <Col md="2">
            <Nav>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  <b>
                    CHUB <sup>ao</sup> &copy; {new Date().getFullYear()}
                  </b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="#" tag={Link} onClick={() => setFormModal(true)}>
                  Política de Privacidade
                </NavLink>
              </NavItem>
            </Nav>
          </Col>

          {/* <Col md="2">
            <h4 className="title">Customers</h4>
          </Col> */}

          <Col sm="1" xs="6" className="mt-4 mt-sm-4">
            <img
              alt="..."
              className="img-fluid rounded shadow"
              src={require("assets/img/BMW.png")}
              style={{ width: "100px" }}
            />
          </Col>
          <Col sm="1" xs="6" className="mt-4 mt-sm-4">
            <img
              alt="..."
              className="img-fluid rounded shadow"
              src={require("assets/img/Emblem_of_Angola.png")}
              style={{ width: "100px" }}
            />
          </Col>

          {/* <Col sm="1" xs="6" className="mt-4 mt-sm-4">
            <img
              alt="..."
              className="img-fluid rounded shadow"
              src={require("assets/img/Mercedes.png")}
              style={{ width: "100px" }}
            />
          </Col> */}

          {/* <Col md="3"></Col> */}

          {/* <Col sm="8" xs="6">
            <section className="section section-lg section-safe">
              <img
                alt="..."
                className="path"
                src={require("assets/img/path5.png")}
              />
              <Container>
                <Row className="row-grid justify-content-between">
                  <Col md="5">
                    <img
                      alt="..."
                      className="img-fluid floating"
                      src={require("assets/img/chester-wade.jpg")}
                    />
                    <Card className="card-stats bg-danger">
                      <CardBody>
                        <div className="justify-content-center">
                          <div className="numbers">
                            <CardTitle tag="p">100%</CardTitle>
                            <p className="card-category text-white">Safe</p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-stats bg-info">
                      <CardBody>
                        <div className="justify-content-center">
                          <div className="numbers">
                            <CardTitle tag="p">573 K</CardTitle>
                            <p className="card-category text-white">
                              Satisfied customers
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-stats bg-default">
                      <CardBody>
                        <div className="justify-content-center">
                          <div className="numbers">
                            <CardTitle tag="p">10 425</CardTitle>
                            <p className="card-category text-white">Business</p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </Col> */}
        </Row>
        <CustomModal
          title="Política de Privacidade"
          formModal={formModal}
          setFormModal={setFormModal}
        >
          <p>
            Na CHUB AO colectamos e gerenciamos dados do usuário de acordo com o
            a Política de Privacidade de Angola. Abaixo encontrará informações
            sobre visitas e uso do site CHUB AO. A CHUB AO é sensível às
            questões de privacidade na Internet e reconhece a importância de
            proteger todas as informações que recebemos dos nossos visitantes.
            Esta política de privacidade se aplica aos dados fornecidos por você
            e colectado pelo site CHUB AO. Usaremos informações fornecidas para
            processar solicitações, consultas e providenciar uma experiência
            mais de navegação mais personalizada. CHUB AO garante que todos as
            informações pessoais são tratadas de acordo com legislação de
            privacidade de dados aplicável em Angola.
          </p>
        </CustomModal>
      </Container>
    </footer>
  );
}
