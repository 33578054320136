/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";

export default function Services() {
  return (
    <div className="section section-basic" id="services">
      <img alt="..." className="path" src={require("assets/img/path1.png")} />
      <Container>
        <div className="space-50" />
        <div id="images">
          <div className="title">
            <h3 className="mb-3">Serviços</h3>
          </div>
          <Row className="mt-5 mt-sm-5 text-center">
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Desenvolvimento Web
              </small>
              <img
                id="web-development"
                alt="..."
                className="img-fluid rounded shadow pointer"
                src={require("assets/img/icons8-web-development-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="web-development">
                Desenvolvimento web é o processo de construção, programação e
                manutenção de sites e aplicações web. Na HUB <sup>ao</sup>{" "}
                trabalhamos com diversas linguagens de programação, como HTML,
                CSS e JavaScript/Typescript, UI framework como React para
                desenvolver sites e web aplicações.
              </UncontrolledTooltip>
            </Col>
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Serviços de nuvem e monitoramento
              </small>
              <img
                id="cloud"
                alt="..."
                className="img-fluid rounded-circle shadow pointer"
                src={require("assets/img/icons8-clouds-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="cloud">
                A nuvem é uma rede global de servidores remotos que armazenam
                dados, executar aplicativos e fornecer conteúdo e serviços
                através do Internet. É um espaço virtual que permite aos
                usuários acessar arquivos e dados de qualquer dispositivo com
                conexão à Internet. Na HUB <sup>ao</sup> nós trabalham os
                seguintes provedores de nuvem AWS e Digital Ocean.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Integração de Sistemas
              </small>
              <img
                id="system-integration"
                alt="..."
                className="img-fluid rounded shadow-lg"
                src={require("assets/img/icons8-module-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip
                placement="bottom"
                target="system-integration"
              >
                A integração de sistemas é o processo de combinar diferentes
                sistemas e aplicações em um sistema único e coeso que funciona
                como um. O objetivo é fazer com que esses sistemas funcionem
                juntos sem problemas para que possam compartilhar informações e
                processos com mais eficiência. Na HUB <sup>ao</sup>, fornecemos
                as seguintes APIs de integração de sistema, Pagamentos, SMS,
                USSD, Sistema para Sistema.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                DevOps
              </small>
              <img
                id="devops"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-devops-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="devops">
                DevOps é uma metodologia de desenvolvimento de software que
                combina práticas, ferramentas e uma filosofia cultural para
                melhorar e acelerar acelerar o ciclo de vida de desenvolvimento
                de software. O termo DevOps é um combinação das palavras
                “desenvolvimento” e “operações”. Nós adotou com sucesso a
                metodologia DevOps, mudando a forma como trabalhamos e
                colaboramos. Da mudança cultural à automação com integração
                contínua e implantação contínua (CI/CD).
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-5 mt-sm-5 text-center">
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Design UX/UI
              </small>
              <img
                id="design-ux-ui"
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-design-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="design-ux-ui">
                Design UX/UI é o processo de criação de um produto ou serviço
                que fornece uma boa experiência ao usuário, projetando o usuário
                interface (UI) e experiência do usuário (UX), usamos Figma para
                projetar colaborativamente, construindo produtos significativos
                com projetar, prototipar e desenvolver perfeitamente.
              </UncontrolledTooltip>
            </Col>
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                SEO
              </small>
              <img
                id="seo"
                alt="..."
                className="img-fluid rounded-circle shadow"
                src={require("assets/img/icons8-seo-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="seo">
                SEO (otimização de mecanismo de pesquisa) - trata de ajudar na
                pesquisa mecanismos entendem seu conteúdo e ajudam os usuários a
                encontrar seu site e tomar uma decisão sobre se eles devem
                visitar seu site através de um mecanismo de busca. Podemos
                ajudar nossos clientes a alcançar Google, bem como os principais
                mecanismos de pesquisa em primeiro lugar.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Marketing
              </small>
              <img
                id="marketing"
                alt="..."
                className="img-fluid rounded shadow-lg"
                src={require("assets/img/icons8-web-advertising-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="marketing">
                O marketing online, também conhecido como marketing digital, é o
                uso de a Internet e outras tecnologias digitais para promover
                uma produtos, serviços ou marca da empresa para clientes em
                potencial. Também fornecemos gerenciamento profissional e
                implementação de todos os recursos do Google Adwords, bem como
                mídias sociais, nossos equipe especializada cuidará da sua marca
                em todas as principais redes sociais plataformas de mídia,
                Search Engine Marketing (SEM), Web Analytics, Gerenciamento de
                relacionamento com o cliente (CRM), bem como e-mail campanhas.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Gerenciamento de projetos
              </small>
              <img
                id="project-management"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-project-management-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip
                placement="bottom"
                target="project-management"
              >
                O gerenciamento de projetos é o processo de planejar, organizar
                e executar um projeto para atingir seus objetivos dentro das
                restrições de tempo, orçamento e escopo. Envolve coordenar
                pessoas e recursos para cumprir prazos e entregar um produto,
                serviço ou entregável. Podemos ajudar o seu negócio com projeto
                E2E gerenciamento desde a definição de escopos, identificação de
                entregas e gerenciamento de risco.
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-5 mt-sm-5 text-center">
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Desenvolvimento Móvel
              </small>
              <img
                id="mobile"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-android-phone-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="mobile">
                O desenvolvimento móvel é o processo de criação de software
                aplicativos executados em dispositivos móveis, como smartphones,
                tablets e assistentes digitais. O processo envolve vários
                estágios, incluindo: idealização, design, codificação, teste e
                Implantação. Na CHUB <sup>ao</sup> trabalhamos com React Native
                para desenvolver mobile aplicações.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Desenvolvimento de software
              </small>
              <img
                id="software-development"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-software-development-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip
                placement="bottom"
                target="software-development"
              >
                Desenvolvimento de software é o processo de criar, projetar,
                testes e manutenção de aplicativos de software. Envolve um
                diversas atividades, incluindo: Concepção de projetos, Análise
                de requisitos, design de software, codificação, testes,
                Implantação.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Segurança cibernética
              </small>
              <img
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-cyber-security-100.png")}
                style={{ width: "100px" }}
              />
            </Col>

            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Q&A - Teste de controle de qualidade
              </small>
              <img
                id="q-a"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-test-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="q-a">
                O teste de controle de qualidade é um processo que avalia a
                funcionalidade, segurança, desempenho e usabilidade de um
                produto de software. Na CHUB <sup>ao</sup> garante que o
                software atenda às necessidades e expectativas do usuário. Nós
                testar software para verificar se o software funciona conforme
                esperado, enquanto nós Controle de qualidade para garantir que o
                processo de desenvolvimento de software produza software de alta
                qualidade. Algumas das ferramentas com as quais trabalhamos são
                Dramaturgo, JUnit e K6.
              </UncontrolledTooltip>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
