import React from "react";

import { Modal } from "reactstrap";

export default function CustomModal(props) {
  return (
    <Modal
      modalClassName="modal-black"
      isOpen={props.formModal}
      toggle={() => props.setFormModal(false)}
    >
      <div className="modal-header justify-content-center">
        <button className="close" onClick={() => props.setFormModal(false)}>
          <i className="tim-icons icon-simple-remove text-white" />
        </button>
        <div className="text-muted text-center ml-auto mr-auto">
          <h3 className="mb-0">{props.title}</h3>
        </div>
      </div>
      <div className="modal-body" data-background-color="black">
        {props.children}
      </div>
    </Modal>
  );
}
